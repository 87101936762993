import React from "react"
import styled from 'styled-components'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom"
import { Navigation } from './components/navigation/Navigation'
import { Home } from './components/home/Home'
import { HowItWorks } from './components/HowItWorks'

const Container = styled.div`
    height: 100vh;
`

export default function App() {
    return (
        <Container className='app'>
            <Router>
                <Navigation/>
                    <Switch>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/how-it-works" component={HowItWorks}/>
                    </Switch>
            </Router> 
        </Container>
    )
}