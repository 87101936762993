import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    height: 30px;
    background-color: var(--gray-lighest);
`


function TopSection() {
    return (
        <Container>Filters etc</Container>
    )
}

export default TopSection;