import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    height: 100vh;
    font-size: 30px;
    margin: 40px 0 0 160px;
`

export function HowItWorks() {
    return (
        <Container>
            How it Works
        </Container>
    );
}