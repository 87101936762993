import React from 'react'
import styled from 'styled-components'
import { Logotype } from '../icons'
import { Link } from 'react-router-dom'

const Container = styled.div`
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 25px 50px 25px 50px;

    a {
        margin-right: 25px;

        :nth-of-type(4) {
            margin-left: auto;
            color: var(--pink);
            background-color: var(--green-light);
            border-radius: 2px;
            padding: 8px 25px;
        }

        :first-of-type {
            margin-right: 30px;
        }

        :last-of-type {
            margin-right: 0;
        }
    }
`

const Logo = styled(Link)`
    svg {
        width: 129px;
        height: 50px;
    }
`

const NavLink = styled(Link)`
    font-weight: 500;
    letter-spacing: 0.5px;
`

function Desktop() {
    return (
        <Container>
            <Logo to='/'><Logotype/></Logo>
            <NavLink to='/how-it-works'>How it Works</NavLink>
            <NavLink to='/faq'>FAQs</NavLink>
            <NavLink to='/post-a-gig'>Post a gig</NavLink>
            <NavLink to='/sign-in'>Sign in</NavLink>
        </Container>
    )
}

const MobileContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    background-color: white;
`

function Mobile() {
    return (
        <MobileContainer>
            Mobile nav
        </MobileContainer>
    )
}

export function Navigation() {
    return (
        <Mobile/>
    )
}