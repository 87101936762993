import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    background-color: white;
    height: calc(100% - 35px);
    margin: 15px;
    border-radius: 6px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
`

function SwipeCard() {
    return (
        <Container>I'm a swipe card</Container>
    )
}

export default SwipeCard;