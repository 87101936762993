import React from 'react';
import styled from 'styled-components';
import TopSection from './TopSection';
import SwipeContainer from './SwipeContainer';

const Container = styled.div`
    background-color: var(--gray-lighest);
    height: calc(100% - 70px);
`

function MobileHome() {
    return (
        <Container>
            <TopSection/>
            <SwipeContainer/>
        </Container>
    )
}

export default MobileHome;