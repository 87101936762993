import React, { Component } from 'react';
import styled from 'styled-components';
import SwipeCard from './SwipeCard';
import 'swiper/css/swiper.min.css'
import './Mobile.css';
import Swiper from 'swiper';

const Container = styled.div`
    background-color: blue;
    height: calc(100% - 30px);
`

const categoryData = ['Lorem', 'Lorem', 'Dolor', 'Ipsum', 'Dolor', 'Dolor'];


class SwipeContainer extends Component {

    componentDidMount() {
        this.swiper = new Swiper('.swiper-container', {

            pagination: {
                el: '.swiper-pagination',
                type: 'progressbar'
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        })
    }

    render() {
        return (
            <Container>
                <div className="swiperMainContainer">
                    {/* Here we will be adding swiper container */}
                    <div className="swiper-container">
                        <div className="swiper-wrapper">
                            {categoryData.map((el, index) =>
                                <div className="swiper-slide" key={index}>
                                    <SwipeCard/>
                                </div>
                            )}
                        </div>
                        {/* -- If we need pagination -- */}
                        {/*<div class="swiper-pagination"></div>*/}
                        {/* -- If we need navigation buttons -- */}
                        {/*<div class="swiper-button-prev"></div>*/}
                        {/*<div class="swiper-button-next"></div>*/}
                    </div>
                </div>
            </Container>
        )
    }
}

export default SwipeContainer;