import React from 'react';
import styled from 'styled-components';
import Mobile from './mobile/Mobile';

export function Home() {

    return (
        <>
            <Mobile/>
        </>
    )
}